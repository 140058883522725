<template>
    <div>
      <b-row>
      <b-col lg="6" md="6" sm="6" class="heading-title">
        <h4>Manage Customers</h4>
        <b-breadcrumb class="p-0 m-0" id="breadcrumb-heading">
          <b-breadcrumb-item to="/dashboard" custom> Home </b-breadcrumb-item>
          <b-breadcrumb-item to="/Customer/view_customer">Manage Customers</b-breadcrumb-item>
          <b-breadcrumb-item active>View</b-breadcrumb-item>
        </b-breadcrumb>
      </b-col>
      <b-col lg="6" md="6" sm="6" class="btns-header"> </b-col>
    </b-row>
    <br>
    <div class="d-flex justify-content-between mt-3">
			<div>
				<h4> Customer ID - {{ form.customer_id }}
					
				</h4> 
			</div>
			<div>
			
				
			</div>
		</div> 
		<b-row class="mt-4" >
     
			
			
			<b-col md="6">
				<h6 class="custom-text"><span>Personal Details</span></h6>
				<b-row>
					<b-col cols="4" md="4" class="text-left mt-2">Full name</b-col>
					<b-col cols="12" md="8" sm="12">
						
						<b-form-group id="f_name"  >
							<b-form-input 
							class="form-control venue-custom-input" :disabled="edit==false" 
							name="" id="" aria-describedby="helpId" placeholder=" first name" :value="form.name">
							</b-form-input>
						
						</b-form-group>
					</b-col>
					
				</b-row>
			
				<b-row>
					<b-col cols="4" md="4" class="text-left mt-4">Phone number</b-col>
					<b-col cols="12" md="8">
						<b-form-group id="phone"  >
							<b-form-input  type="text"
							class="form-control venue-custom-input" :disabled="edit==false"
						
							name="" id="" aria-describedby="helpId" placeholder="Enter phone number" :value="form.phone">
							</b-form-input>
							
						</b-form-group> 
					</b-col>
					
				</b-row>
				
				
				<h6 class="custom-text mt-4"><span>Address Details</span></h6>
				<b-row>
					<b-col cols="4" md="4" class="text-left mt-2">Address</b-col>
					<b-col cols="12" md="8" class="mt-2">
						
						<b-form-group id="address"  >
							<b-form-textarea 
							class="form-control venue-custom-input" :disabled="edit==false"  name="" 
							id="" aria-describedby="helpId" placeholder="Enter Address"  :value="form.address"
							>
							</b-form-textarea>
							
						</b-form-group>
						
					</b-col>
					
				</b-row>
				<b-row>
					<b-col cols="4" md="4" class="text-left mt-2"> Pincode</b-col>
					
					<b-col cols="12" md="8">
						<b-form-group id="pincode"  >
							<b-form-input 
							class="form-control venue-custom-input" :disabled="edit==false"  name="" 
							id="" aria-describedby="helpId" placeholder="Enter pincode"  :value="form.pincode"
							>
							</b-form-input>
						
						</b-form-group>
					</b-col>
				</b-row>
				
				<b-row>
					
					<b-col cols="4" md="4" class="text-left mt-2">City</b-col>
					<b-col cols="12" md="8">
						
						<b-form-group id="city"  >
							<b-form-input 
							class="form-control venue-custom-input" :disabled="edit==false"  name="" 
							id="" aria-describedby="helpId" placeholder="Enter city"  :value="form.city"
						>
							</b-form-input>
						
						</b-form-group>
						
					</b-col>
					
				</b-row> 
				<b-row>
					<b-col cols="4" md="4" class="text-left mt-2"> State</b-col>
					
					<b-col cols="12" md="8">
						
						<b-form-group id="state"  >
							<b-form-input 
							class="form-control venue-custom-input" :disabled="edit==false"  name="" 
							id="" aria-describedby="helpId" placeholder="Enter state"  :value="form.state"
						>
							</b-form-input>
							
						</b-form-group>
						
					</b-col>
				</b-row>
				
				
				
			</b-col>
			
			<b-col md="6">
				
				<h6 class="custom-text "><span>Contact Details</span></h6>
				<b-row>
					<b-col cols="4" md="4" class="text-left mt-2">Email address</b-col>
					<b-col cols="12" md="8">
						<b-form-group id="email"  >
							<b-form-input  type="text"
							class="form-control venue-custom-input" :disabled="edit==false" 
							name="" id="" aria-describedby="helpId"  :value="form.email"
							placeholder="Enter Email Address">
							</b-form-input>
							
						</b-form-group>
						
					</b-col>
					
				</b-row>
				<b-row>
					<b-col cols="4" md="4" class="text-left mt-5"> </b-col>
					
					<b-col cols="12" md="8">
						
						
					</b-col>
				</b-row>
				
				<h6 class="custom-text mt-4"><span>About Yourself</span></h6>
				<b-row>
					<b-col cols="4" md="4" class="text-left mt-2">About</b-col>
					<b-col cols="12" md="8" class="mt-2">
						
						<b-form-group id="about"  >
							<b-form-textarea 
							class="form-control custom-disable-teatarea"  rows="9" name="" 
							id="" aria-describedby="helpId" placeholder="Enter About yourself"
							>
							</b-form-textarea>
							
						</b-form-group>
						
					</b-col>
					
				</b-row>
			</b-col>
		</b-row>


    </div>
  </template>
  <script>
  import axios from "axios";
  
  export default {
    name: "customer_view",
  
    data: () => ({
      title: "customer_view",
      form: {
        name: "",
        last_name: "",
        email: "",
        phone: "",
        customer_id: "",
        address: "",
        city: "",
        state: "",
        pincode: "",
      },
      edit:false
    }),
    components: {},
  
    methods: {
      getData() {
        this.id = this.$route.params.id;
  
        axios
          .post("/admin/get_customer_details", {
            customer_id: this.id,
          })
          .then((resp) => {
            if (resp.data.status_code == 200) {
              if (resp.data.status) {
                this.id = resp.data.customer.parent_id;
                this.form.name = resp.data.customer.name;
                this.form.last_name = resp.data.customer.last_name;
                this.form.email = resp.data.customer.email;
                this.form.phone = resp.data.customer.phone;
                this.form.customer_id = resp.data.customer.customer_id;
                this.form.address = resp.data.customer.address;
                this.form.city = resp.data.customer.city;
                this.form.state = resp.data.customer.state;
                this.form.pincode = resp.data.customer.pincode;
              } else {
                this.$router.push("/my-customers");
              }
            }
          });
      },
    },
  
    mounted() {
      this.getData();
    },
  };
  </script>
  <style scoped>
  .row.add-remove-button {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin: 10px 0;
  }
  .row.add-remove-button input {
    height: auto;
    max-width: 340px;
    flex: 1;
  }
  .row.add-remove-button button {
    background: #000;
    text-transform: uppercase;
    font-size: 14px;
    margin-left: 8px;
  }
  .form-group {
    margin: 0;
  }
  @media (max-width: 640px) {
  }
  #urls {
    max-width: 450px;
  }
  </style>